import React from "react";
import { t } from "../../utils/i18n";
import { SelectInput, TextArea } from "../Commons/inputs";
import CustomData from "./customData";

export default (props) => {
  const {
    onChange,
    fieldsVisibility,
    current_organization,
    translation,
    hintText,
    data: {
      client,
      errorFields,
      clientQuantitativeFreeTextCasesData,
      quantitativeType,
      customData,
      clientCustomData,
      setClientCustomData,
      quantitativeCase,
      T
    }
  } = props;

  const onChangeQuantitative = ({
    data,
    action,
    removed,
    type,
    options,
    isMulti
  }) => {
    const selectedCases = client.quantitative_case_ids;
    let value = [];

    if (isMulti) {
      if (action === "remove-value") {
        selectedCases.splice(selectedCases.indexOf(removed), 1);
        value = selectedCases;
      } else if (action === "select-option")
        value = [...new Set([...selectedCases, ...data])];
      else if (action === "clear") {
        removed.forEach((removedValue) => {
          selectedCases.splice(selectedCases.indexOf(removedValue), 1);
        });
        value = selectedCases;
      }
    } else {
      options.forEach((option) => {
        let index = selectedCases.indexOf(option.value);

        if (index > -1) {
          selectedCases.splice(index, 1);
        }
      });

      value = [...new Set([...selectedCases, ...[data]])];
    }

    onChange("client", "quantitative_case_ids")({ data: value, type });
  };

  const onChangeFreeTextQuantitative = (event, quantitative = {}) => {
    const modifiedQuantitative = {
      ...quantitative,
      content: event.target.value
    };
    onChange(
      "cqFreeText",
      modifiedQuantitative
    )({ data: modifiedQuantitative, type: "date" });
  };

  const renderQuantitativeCases = () => {
    return quantitativeType.map((type, index) => {
      const cqFreeText = clientQuantitativeFreeTextCasesData.find(
        (cqFreeText) => {
          return cqFreeText.quantitative_type_id == type.id;
        }
      );

      return (
        <div className="row" key={index}>
          <div className="col-xs-12 col-md-12 col-lg-12">
            {type.field_type == "free_text" && (
              <TextArea
                label={type.name}
                required={type.is_required}
                hintText={type.hint}
                isError={errorFields.includes(`qtt_type_${type.id}`)}
                inlineClassName={type.name}
                T={T}
                value={cqFreeText && cqFreeText.content}
                onChange={(e) => {
                  onChangeFreeTextQuantitative(e, cqFreeText);
                }}
              />
            )}

            {type.field_type == "select_option" && (
              <SelectInput
                T={T}
                isMulti={type.multiple}
                required={type.is_required}
                className={"required-" + type.is_required}
                label={type.name}
                hintText={type.hint}
                isError={errorFields.includes(`qtt_type_${type.id}`)}
                inlineClassName={type.name}
                value={client.quantitative_case_ids}
                onChange={onChangeQuantitative}
                options={quantitativeCase
                  .filter((ftr) => {
                    return ftr.quantitative_type_id === type.id;
                  })
                  .map((qc) => ({ label: qc.value, value: qc.id }))}
              />
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="containerClass">
      <legend>
        <div className="row">
          <div className="col-xs-12 col-md-6 col-lg-10">
            <p>{T.translate("referralVulnerability.referral_vulnerability")}</p>
          </div>
        </div>
      </legend>

      {renderQuantitativeCases()}
      {customData[0] && <hr />}
      <CustomData
        customData={customData}
        errorFields={errorFields}
        clientCustomData={clientCustomData}
        setClientCustomData={setClientCustomData}
        onChange={onChange}
        T={T}
      />

      {fieldsVisibility.relevant_referral_information == true && (
        <div className="row">
          <div className="col-xs-12 col-md-12 col-lg-12">
            <TextArea
              label={t(
                translation,
                "clients.form.relevant_referral_information"
              )}
              onChange={onChange("client", "relevant_referral_information")}
              value={client.relevant_referral_information}
              inlineClassName="relevant-info"
              hintText={
                current_organization.short_name == "ratanak"
                  ? hintText.custom.ratanak.relevant_info
                  : hintText.custom.relevant_info
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};
